/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Source: https://www.outbrain.com/help/advertisers/dynamic-values/
 *
 * All event listeners
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.outbrain?.advertiser_id && !wpmDataLayer?.pixels?.outbrain?.loaded) {
		if (wpm.canIFire("ads", "outbrain-ads")) wpm.loadOutbrainPixel()
	}
})

// view search event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.search)
		wpm.consoleLog("Outbrain: search event sent")

	} catch (e) {
		console.error(e)
	}
})

// ViewContent event
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.view_content)
		wpm.consoleLog("Outbrain: view_content event sent")

	} catch (e) {
		console.error(e)
	}
})

// AddToCart event
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.add_to_cart)
		wpm.consoleLog("Outbrain: add_to_cart event sent")

	} catch (e) {
		console.error(e)
	}
})

// begin_checkout event
jQuery(document).on("wpmBeginCheckout", () => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.start_checkout)
		wpm.consoleLog("Outbrain: start_checkout event sent")

	} catch (e) {
		console.error(e)
	}
})

// view order received page event
// https://www.outbrain.com/help/advertisers/dynamic-values/
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.outbrain?.loaded) return

		const data = {
			orderValue: String(wpmDataLayer.order.value.marketing),
			currency  : wpmDataLayer.order.currency,
			orderId   : String(wpmDataLayer.order.id),
		}

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.purchase, data)
		wpm.consoleLog("Outbrain: purchase event sent", data)

	} catch (e) {
		console.error(e)
	}
})
