/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load GA4 premium event listeners
 * */

// user log in event
jQuery(document).on("wpmLogin", () => {

	try {
		if (!wpmDataLayer?.pixels?.google?.analytics?.eec) return
		if (!wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) return
		if (!wpm.googleConfigConditionsMet("analytics")) return

		const data = {
			send_to: wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
		}

		wpm.gtagLoaded().then(() => {
			gtag("event", "login", data)
			wpm.consoleLog("Google Analytics: login event sent", data)
		})
	} catch (e) {
		console.error(e)
	}
})


/**
 * Scroll tracking event
 */

jQuery(document).on("pmwScrollDepth", (event, scrollEvent) => {

	if (!wpmDataLayer?.pixels?.google?.analytics?.eec) return
	if (!wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) return
	if (!wpm.googleConfigConditionsMet("analytics")) return

	const data = {
		send_to: wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
		value  : scrollEvent.threshold,
	}

	wpm.gtagLoaded().then(() => {
		gtag("event", "scroll", data)
		wpm.consoleLog("Google Analytics: scroll event sent", data)
	})
})

/**
 * Attach an event listener to all links that contain tel: links
 */
jQuery(document).on("click", "a[href^='tel:']", (event) => {

	// Get the phone number from the link
	let phoneNumber = event.target.href.replace("tel:", "")

	// Trim the phone number and remove all whitespaces
	phoneNumber = phoneNumber.replace(/\s/g, "")

	const data = {
		send_to             : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
		clicked_phone_number: phoneNumber,
	}

	wpm.gtagLoaded().then(() => {
		gtag("event", "phone_number_click", data)
		wpm.consoleLog("Google Analytics: phone_number_click event sent", data)
	})
})

/**
 * Attach an event listener to all links that contain mailto: links
 */
jQuery(document).on("click", "a[href^='mailto:']", event => {

	console.log("email click")
	console.log("email address: " + wpm.getEmailFromTarget(event.target))
	console.log("event.target: ", event.target)

	const data = {
		send_to      : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
		clicked_email: wpm.getEmailFromTarget(event.target),
	}

	wpm.gtagLoaded().then(() => {
		gtag("event", "email_click", data)
		wpm.consoleLog("Google Analytics: email_click event sent", data)
	})
})

// Track load time in GA4 in seconds
// https://www.lovesdata.com/blog/page-load-google-analytics
jQuery(window).on("load", () => {

	if (!wpmDataLayer?.pixels?.google?.analytics?.ga4?.page_load_time_tracking) return

	let timeToLoad = (performance.now() / 1000).toFixed(2)

	// console.log("Time to load: " + timeToLoad + " seconds");

	const data = {
		send_to               : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
		page_load_time_seconds: timeToLoad,
	}

	wpm.gtagLoaded().then(() => {
		gtag("event", "page_load_time", data)
		wpm.consoleLog("Google Analytics: page_load_time event sent", data)
	})
})
