/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * All event listeners
 * */

/**
 * Source: https://help.taboola.com/hc/en-us/articles/360009027493-Tracking-Dynamic-Conversion-Values-
 **/

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.taboola?.account_id && !wpmDataLayer?.pixels?.taboola?.loaded) {
		if (wpm.canIFire("ads", "taboola-ads")) wpm.loadTaboolaPixel()
	}
})

// view search event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.taboola?.loaded) return

		let data = {
			id    : wpmDataLayer.pixels.taboola.account_id,
			notify: "event",
			name  : wpmDataLayer.pixels.taboola.event_names.search,
		}

		_tfa.push(data)
		wpm.consoleLog("Taboola: search event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// add-to-cart event
// No product value transmitted, as Taboola only supports the "revenue" field.
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.taboola?.loaded) return

		let data = {
			id      : wpmDataLayer.pixels.taboola.account_id,
			notify  : "event",
			name    : wpmDataLayer.pixels.taboola.event_names.add_to_cart,
			quantity: product.quantity,
			currency: product.currency,
			revenue : product.quantity * product.price,
		}

		_tfa.push(data)
		wpm.consoleLog("Taboola: add_to_cart event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// add-to-wishlist event
// No product value transmitted, as Taboola only supports the "revenue" field.
jQuery(document).on("wpmAddToWishlist", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.taboola?.loaded) return

		let data = {
			id      : wpmDataLayer.pixels.taboola.account_id,
			notify  : "event",
			name    : wpmDataLayer.pixels.taboola.event_names.add_to_wishlist,
			quantity: product.quantity,
			currency: product.currency,
			revenue : product.quantity * product.price,
		}

		_tfa.push(data)
		wpm.consoleLog("Taboola: add_to_wishlist event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// start-checkout event
// No product value transmitted, as Taboola only supports the "revenue" field.
jQuery(document).on("wpmBeginCheckout", () => {

	try {
		if (!wpmDataLayer?.pixels?.taboola?.loaded) return

		let data = {
			id    : wpmDataLayer.pixels.taboola.account_id,
			notify: "event",
			name  : wpmDataLayer.pixels.taboola.event_names.start_checkout,
		}

		_tfa.push(data)
		wpm.consoleLog("Taboola: start_checkout event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// view-content event
// No product value transmitted, as Taboola only supports the "revenue" field.
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.taboola?.loaded) return

		let data = {
			id    : wpmDataLayer.pixels.taboola.account_id,
			notify: "event",
			name  : wpmDataLayer.pixels.taboola.event_names.view_content,
		}

		if (product) {
			data.currency = product.currency
			data.quantity = product.quantity
			data.revenue  = product.quantity * product.price
		}

		_tfa.push(data)
		wpm.consoleLog("Taboola: view_content event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// view order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.taboola?.loaded) return

		let data = {
			id      : wpmDataLayer.pixels.taboola.account_id,
			notify  : "event",
			name    : wpmDataLayer.pixels.taboola.event_names.purchase,
			revenue : wpmDataLayer.order.value.marketing,
			currency: wpmDataLayer.order.currency,
			orderid : wpmDataLayer.order.id,
		}

		if (wpmDataLayer.order.items) {
			let itemCount = 0
			wpmDataLayer.order.items.forEach(item => {
				itemCount += parseInt(item.quantity)
			})

			data.quantity = itemCount
		}

		_tfa.push(data)
		wpm.consoleLog("Taboola: purchase event sent", data)

	} catch (e) {
		console.error(e)
	}
})
