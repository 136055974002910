/**
 * Copyright SweetCode. All rights reserved.
 *
 * Load VWO event listeners
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.vwo?.account_id && !wpmDataLayer?.pixels?.vwo?.loaded) {
		if (wpm.canIFire("analytics", "vwo")) wpm.loadVwoPixel()
	}
})

// view order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.vwo?.loaded) return

		const data = [
			'track.revenueConversion',
			wpmDataLayer.order.value.total,
		]
		window.VWO.push(data);
		wpm.consoleLog("VWO Pixel: track.revenueConversion event sent", data)

	} catch (e) {
		console.error(e)
	}
})

