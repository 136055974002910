/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load LinkedIn event listeners
 *
 * https://www.linkedin.com/help/lms/answer/a425606?lang=en-US
 *
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.linkedin?.partner_id && !wpmDataLayer?.pixels?.linkedin?.loaded) {
		if (wpm.canIFire("ads", "linkedin")) wpm.load_linkedin_pixel()
	}
})

// view search event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.linkedin?.loaded) return
		if (!wpmDataLayer?.pixels?.linkedin?.conversion_ids?.search) return

		const data = {
			conversion_id: wpmDataLayer.pixels.linkedin.conversion_ids.search,
		}

		window.lintrk("track", data)
		wpm.consoleLog("LinkedIn: search event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// view_content event
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.linkedin?.loaded) return
		if (!wpmDataLayer?.pixels?.linkedin?.conversion_ids?.view_content) return

		const data = {
			conversion_id: wpmDataLayer.pixels.linkedin.conversion_ids.view_content,
		}

		window.lintrk("track", data)
		wpm.consoleLog("LinkedIn: view_content event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// add_to_list event
jQuery(document).on("wpmAddToWishlist", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.linkedin?.loaded) return
		if (!wpmDataLayer?.pixels?.linkedin?.conversion_ids?.add_to_list) return

		const data = {
			conversion_id: wpmDataLayer.pixels.linkedin.conversion_ids.add_to_list,
		}

		window.lintrk("track", data)
		wpm.consoleLog("LinkedIn: add_to_list event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// add-to-cart event
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.linkedin?.loaded) return
		if (!wpmDataLayer?.pixels?.linkedin?.conversion_ids?.add_to_cart) return

		const data = {
			conversion_id: wpmDataLayer.pixels.linkedin.conversion_ids.add_to_cart,
		}

		window.lintrk("track", data)
		wpm.consoleLog("LinkedIn: add_to_cart event sent", data)

	} catch (e) {
		console.error(e)
	}
})


// start-checkout event
jQuery(document).on("wpmBeginCheckout", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.linkedin?.loaded) return
		if (!wpmDataLayer?.pixels?.linkedin?.conversion_ids?.start_checkout) return

		const data = {
			conversion_id: wpmDataLayer.pixels.linkedin.conversion_ids.start_checkout,
		}

		window.lintrk("track", data)
		wpm.consoleLog("LinkedIn: start_checkout event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// View order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.linkedin?.loaded) return
		if (!wpmDataLayer?.pixels?.linkedin?.conversion_ids?.purchase) return

		const data = {
			conversion_id: wpmDataLayer.pixels.linkedin.conversion_ids.purchase,
		}

		window.lintrk("track", data)
		wpm.consoleLog("LinkedIn: purchase event sent", data)

	} catch (e) {
		console.error(e)
	}
})

